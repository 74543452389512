<template>
  <v-main>

    <v-container class="text-center p-4 pt-3" style="font-size:105%">
      <h1 style="font-size:225%">Become a TipBit Angel Advisor</h1>

      <!-- <v-btn @click="sendEmail">Send Email</v-btn> -->

      <template v-if="!show">

        <v-row no-gutters>
          <v-col class="mt-2">
            <v-sheet
              rounded
              color="green lighten-4"
              elevation="1"
              class="p-3"
            >
              <h5>Great! Welcome to the TipBit community! If you have any questions you can always send an email to <a href="mailto:service@tipbit.co">service@tibtit.co</a>.</h5>
              <h5>Thank you and have a great day!</h5>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="mt-3">
            <v-btn color="primary" to="/">Sounds Good!</v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-if="show">

        <v-row no-gutters>
          <v-col class="mt-2">
            <p>Now you can become a shareholder and advisor to TipBit! The future of tipping.</p>
          </v-col>
        </v-row>

        <iframe src="https://player.vimeo.com/video/794679560?h=6dbc9e4974" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>


        <!-- <v-row no-gutters>
          <v-col>
            <v-btn color="blue lighten--3" class="text-white" @click="learnMoreDialog = true">Learn More</v-btn>
          </v-col>
        </v-row> -->

        <v-row no-gutters>
          <v-col>
            <h6>Need more info? <a href="https://wefunder.com/tipbit/">Preview our crowdfunder</a></h6>
            <h5 class="mb-0 mt-4" style="font-size:150%">Sign me up already!</h5>
            <v-select 
              v-model="angel.pledge" 
              :items="items"
              @change="checkSelection"
            >
             <template v-slot:selection="{ item }">
                <span class="d-flex justify-center" style="width: 100%;">
                {{ item }}
                </span>
              </template>
            </v-select>
            <div v-if="showPledge">of TipBit Inc. and become an angel advisor</div>
          </v-col>
        </v-row>

        <v-row no-gutters class="mt-4" v-if="showPledge">
          <v-col>
            <u>We have <span style="color:red">{{ available }} of 50</span> opportunities available</u> 
          </v-col>
        </v-row>

        <v-row no-gutters class="mt-3">
          <v-col>

            <v-text-field
              v-model="angel.firstName"
            >
              <template #label>
                <span class="red--text"><strong>* </strong></span>First Name
              </template>
            </v-text-field>

             <v-text-field
              v-model="angel.lastName"
            >
              <template #label>
                <span class="red--text"><strong>* </strong></span>Last Name
              </template>
            </v-text-field>


            <v-text-field
              v-model="angel.email"
            >
            <template #label>
                <span class="red--text"><strong>* </strong></span>Email
              </template>
            </v-text-field>

            <v-text-field
              @input="formatPhoneNumber"
              v-model="angel.phoneNumber"
            >
            <template #label>
                <span class="red--text" v-if="showPledge"><strong>* </strong></span>Phone Number
              </template>
            </v-text-field>

            <v-textarea
              label="Additional Comments"
              v-model="angel.comment"
              rows="2"
            >
            </v-textarea>

          </v-col>
        </v-row>

        <v-row no-gutters class="mb-5 pb-2">
          <v-col>
            <v-btn
              color="primary"
              @click="submitAngel"
            >Submit</v-btn>
          </v-col>
        </v-row>

      <hr />

      <v-row class="text-center mt-1">
        <v-col>
          <v-btn to="/angel-advisor/" color="info">Learn More</v-btn>
        </v-col>
      </v-row>

      </template>
    </v-container>

    <v-dialog
      v-model="learnMoreDialog"
    >
      <v-card>
        
        <v-card-title>
          Angel Advisor Benefits
          <v-spacer></v-spacer>
          <v-btn icon @click="learnMoreDialog = false">
          <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row no-gutters>
            <v-col>
              <p>Become an Angel Advisor for the World's 1st Crypto Tipping App.</p>
              <ul>
                <li>Your voice is heard</li>
                <li>Exclusive access to ...</li>
                <li>Shares of TipBit Inc. which can appreciate in value</li>
              </ul>

              <p>TipBit is the first Bitcoin wallet for tipped workers, making it easy to accept cryptocurrency as gratuity. TipBit is the future of tipping.</p>

              <p>We're inviting up to 50 tipped workers and industry professionals like you to become Angel Advisors and ground floor investors for as little as two-hundred bucks.</p>
              <p>
                Tipbit.co is the new Bitcoin wallet for hospitality! Now, you can immediately beging accepting crypo ... easy to begin accepting cryptocurrency, run tipping analytics, and much more! But … that’s just the beginning! You have an opportunity to not only purchase a stake in Tipbit, but also have your voice heard guiding the development of the product into something that’s perfect just for you. It’s fun and affordable to be a shareholder so sign up today!
              </p>
            </v-col>    
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="learnMoreDialog = false"
            color="blue darken-1"
            text
            class="no-uppercase"
          >
            OK
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
    

  </v-main>
</template>


<script>
import { db } from "@/main"
import { getFunctions, httpsCallable } from "firebase/functions";
import { addDoc, getDocs, collection, query } from "firebase/firestore"

export default {

  data: function () {
      return {

        showPledge: false,

        available: null,

        learnMoreDialog: false,

        angel: {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          comment: '',
          pledge: "---- Select ----",
          pledgeAmount: '',
        },

        items: [
          "---- Select ----",
          "I'll pledge $100 for 400 shares",
          "I'll pledge $200 for 800 shares",
          "I'll pledge $300 for 1,200 shares",
          "I'll pledge $400 for 1,600 shares",
          "I'll pledge $500 for 2,000 shares",
          "I'll pledge $1,000 for 4,000 shares",
          "No pledge, but keep me in the loop",
        ],

        show: true,

        angels: [],

      }
  },

  mounted() {
    this.fetchAngelData()
  },

  methods: {

    checkSelection() {
      if (this.angel.pledge.match(/No/) || this.angel.pledge.match(/--- Select ---/)) {
        this.showPledge = false
      }  
      else {
        this.showPledge = true
      }
    },

    fetchAngelData() {
      const angels = query(collection(db, "angels"))
      getDocs(angels).then((result) => {
        result.forEach((doc) => {
        console.log(doc.data().pledgeAmount)
        if (doc.data().pledgeAmount > 0)
          this.angels.push(doc.data())
        }) 
        this.available = 50 - this.angels.length
      })
    },

    async sendEmail() {

      const functions = getFunctions()

      const addMessage = httpsCallable(functions, 'sendEmail')

      return addMessage({ 
          from: 'service@onthejob.site',
          to: 'mattspergel@gmail.com',
          replyTo: 'service@onthejob.site',
          subject: 'Welcome' + this.angel.firstName,
          text: this.angel.firstName + ' ' + this.angel.lastName + ' ' + this.angel.pledgeAmount,
      })

    },

    async submitAngel() {

      this.show = false

      let pledgeAmount = null

      if (this.angel.pledge.match(/100/)) {
        pledgeAmount = 100
      } 
      else if (this.angel.pledge.match(/200/)) {
        pledgeAmount = 200
      }
      else if (this.angel.pledge.match(/300/)) {
        pledgeAmount = 300
      }
      else if (this.angel.pledge.match(/400/)) {
        pledgeAmount = 400
      }
      else if (this.angel.pledge.match(/500/)) {
        pledgeAmount = 500
      }    
      else if (this.angel.pledge.match(/1,200/)) {
        pledgeAmount = 1200
      }    
      else if (this.angel.pledge.match(/No/) || this.angel.pledge.match(/--- Select ---/)) {
        pledgeAmount = 0
      }

      this.angel.pledgeAmount = pledgeAmount
      
      await addDoc(collection(db, "angels"),this.angel)

      const functions = getFunctions()
      const addMessage = httpsCallable(functions, 'sendEmail')

      if (pledgeAmount != undefined) {

        let messageText 
        messageText += 'Welcome ' + this.angel.firstName + '! Your pledge of $' + pledgeAmount + ' has been accepted.'
        
        let messageHTML = '<h1>Welcome to the Community, ' + this.angel.firstName + '</h1>'

        if (pledgeAmount > 0) {
          messageHTML += '<h3>Your pledge of $' + pledgeAmount + ' has been accepted.</h3>'
        }

        messageHTML += '<p>Your contact details are as follows: </p>'
        messageHTML += '<p>'+this.angel.firstName +' '+ this.angel.lastName +'<br />'

        messageHTML += this.angel.email+'<br />'
        messageHTML += this.angel.phoneNumber+'<br />'
        messageHTML += this.angel.comment+'</p>'

        messageHTML += "<p>Please don't hesistate to contact us by replying to this email if any of this information is incorrect.</p>"

        messageHTML += "<hr />"

        messageHTML += '<p>' + this.angel.firstName + ', thank you for your interest in becoming an Angel Advisor for the Worlds 1st Cryptocurrency Tipping Platform TipBit. As of Dec 2022 we\'ve only invited tipped workers (those who receive gratuity) and specific industry professionals to <b>become Angel Advisors</b>.</p>'      
        messageHTML += '<h2>Q: Who is an Angel Advisor?</h2>'
        messageHTML += '<h2>A: An Angel Advisor....</h2>'
        messageHTML += '<ul>' 
        messageHTML += '<li>currently receives gratuity as income.</li>' 
        messageHTML += '<li>has elevated industry experience and history.</li>' 
        messageHTML += '<li>is identified by TipBit as a leader in their field.</li>'
        messageHTML += '<li>wants more control over the tipping experience.</li>'
        messageHTML += '<li>believes those who work for gratuity deserve better.</li>'
        messageHTML += '<li>are ground floor investors and shareholders in TipBit</li>'
        messageHTML += '</ul>'
        messageHTML += '<p><b>TipBit Angel Advisor</b> program is a gateway for an initial 50 tipped workers to enter into a Silicon Valley startup opportunity. While Silicon Valley is littered with deep-pockets and wealthy venture firms, TipBit believes in a \'for-us-by-us\' approach to developing and building the future of tipping, and created the Angel Advisor program as an attainable way for tipped workers to level-up. From time to time TipBit will turn to Angel Advisors for feedback, q&a, testing, suggestion, etc. On occasion Angel Advisors will receive notifications and updates from TipBit, some that may need attention. TipBit recognizes Angel Advisors as industry thought leaders, but Angel Advisor\'s participation is optional and voluntary. Angel Advisors understand their entry level of commitment and investment is not guaranteed to produce a return. All TipBit Angel Advisors will have exclusive access to TipBit Shareholders Portal, and will receive annual reports. While limited to the first 50 people, the goal of the TipBit Angel Advisor program is to generate capitol needed to futurize tipping, with confident hopes in sharing future gains and success.</p>'

        return addMessage({ 
            from: {
              email: 'service@tipbit.co',
              name: 'TipBit Community',
            },
            to: [this.angel.email,'service@tipbit.co'],
            subject: 'Welcome, ' + this.angel.firstName + '!',
            text: messageText,
            html: messageHTML,
        })

      }

    },

    formatPhoneNumber() {
      var x = this.angel.phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.angel.phoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
      // this.formattedNumber = !x[2] ? x[1] : '+1' + x[1] + x[2] + (x[3] ? + x[3] : '')
      // this.formattedNumber = '+1' + this.phoneNumber.replace(/[^0-9]/g, '')
    },

  },


}
  
</script>





