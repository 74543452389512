<template>
  <v-main>
    <v-card v-if="account.name" flat>

      <v-card-title>
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <h3><b>{{account.name}}'s <v-icon x-large color="black" class="mt-n1">mdi-bitcoin</v-icon> Tip Jar</b></h3>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="mt-n3">

        <div class="text-center"> 
          <img
            id="profilePicture"
            thumbnail
            class="w-100"
          />   
        </div>  
        
        <v-stepper 
          v-if="loggedIn"
          v-model="transactionStepper" 
          elevation="0"
        >
          
          <v-stepper-items>

            <v-stepper-content step="1" class="p-0">

              <v-row>
                <v-col class="text-center">
                  <!-- <b-img
                    :src="cryptoDialogAvatar" 
                    id="cryptoDialogAvatar"
                    thumbnail
                  />    -->
                </v-col>  
              </v-row>
            
              <template v-if="loggedIn">

                <v-row no-gutters class="mt-2">
                  <v-col cols="12">
                    <v-text-field
                      v-model="tipInDollars"
                      solo
                      flat
                      @input="convertToBitcoin"
                      class="text-h2 w-100 sendTip"
                      style="font-weight:bold;"
                      readonly
                    />
                  </v-col>
                  <v-col cols="12" class="text-center" style="margin-top:-2em">
                    <b>{{tipInBitcoin}} BTC</b>
                  </v-col>
                  <v-col cols="12" class="text-center" style="margin-top:.1em; text-color:grey">
                    ${{dollarPriceOfMyConfirmedBitcoin}} available
                  </v-col>
                  
                </v-row>
            
                <v-row class="ml-n2 mr-1 text-center">
                  <v-col cols="4">
                    <v-btn large @click="addTipKey(1)" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big">1</b></v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn large @click="addTipKey(2)" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big">2</b></v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn large @click="addTipKey(3)" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big">3</b></v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-1 ml-n2 mr-1 text-center">
                  <v-col cols="4">
                    <v-btn large @click="addTipKey(4)" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big">4</b></v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn large @click="addTipKey(5)" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big">5</b></v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn large @click="addTipKey(6)" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big">6</b></v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-1 ml-n2 mr-1 text-center">
                  <v-col cols="4">
                    <v-btn large @click="addTipKey(7)" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big">7</b></v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn large @click="addTipKey(8)" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big">8</b></v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn large @click="addTipKey(9)" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big">9</b></v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-1 ml-n2 mr-1 text-center">
                  <v-col cols="4">
                    <v-btn large @click="addTipKey('.')" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big">.</b></v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn large @click="addTipKey(0)" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big">0</b></v-btn>
                  </v-col>
                    <v-col cols="4">
                    <v-btn large @click="addTipKey('delete')" fab depressed class="noDoubleTouchZoom" :disabled="loading"><b class="big"><v-icon small>mdi-backspace-outline</v-icon></b></v-btn>
                  </v-col>
                </v-row>

                <!-- <v-row class="mt-4">
                  <v-col no-gutter cols="12">
                    <v-text-field 
                      placeholder="Thank You or Request"
                      v-model="messageToTippee"
                      append-icon="mdi-message-outline"
                    ></v-text-field>
                  </v-col>
                </v-row> -->

                <v-row class="text-center mt-5 pt-1" no-gutters>
                  <v-col cols="12">
                    <v-btn 
                      color="blue lighten-1 white--text"
                      large
                      class="no-uppercase"
                      rounded
                      @click="transactionStepper=2"
                      :disabled="sendTipNextButtonDisabled"
                    >
                      <h4 class="m-0 ml-1">Next</h4>
                      <v-icon x-large right>mdi-menu-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </template>

             

            </v-stepper-content>

            <v-stepper-content step="2" class="pl-0 pt-2" style="font-size:120%">

              <v-overlay :value="transactionProcessing">
                <v-progress-circular
                  :size="80"
                  color="blue"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>

              <v-btn 
                style="z-index:99"
                color="primary"
                large
                icon
                @click="transactionStepper=1"
                v-if="transactionStepper===2"
              >
                <v-icon x-large>mdi-menu-left</v-icon>
              </v-btn>


              <v-row class="ml-3 mr-3" style="margin-top:-2.5em;">                
                <v-col cols="5" class="text-right">
                  <b>Tip</b>
                </v-col>
                <v-col cols="7" class="text-right">
                  <b>{{tipInDollars}}<br /><span style="color:#484848; font-size:80%">{{tipInBitcoin}} BTC</span></b>
                </v-col>
              </v-row>

              <v-row class="ml-3 mr-3">
                <v-col cols="5" class="text-right pt-4">
                  <b>Network fee 
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon  
                        v-bind="attrs"
                        v-on="on"
                        small 
                        class="pb-1"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <div class="text-center">Paid to Bitcoin miners.<br />Not collected to TipBit.</div>
                  </v-tooltip>
                  </b>
                </v-col>
                <v-col cols="7" class="text-right">
                  <!-- <v-select
                    class="m-0 p-0"
                    return-object
                    v-model="transactionFee"
                    :items="[bitcoinTransactionFeesPopUp[0],bitcoinTransactionFeesPopUp[1],bitcoinTransactionFeesPopUp[2],bitcoinTransactionFeesPopUp[3]]"
                    @change="updateTotalTransactionCost($event)"
                  >  
                    <template v-slot:selection="{ item }"> 
                      <div class="text-right" style="width:100%;">
                        <b>{{ item.text }}</b>
                      </div>
                    </template>
                  </v-select> -->

                  <b>${{ transactionFee.text }}</b><br />
                  <b><span style="color:#484848; font-size:80%">{{  transactionFee.value }} BTC</span></b>
            
                  <!-- <v-select
                    class="m-0 p-0"
                    return-object
                    v-model="transactionFee"
                    :items="[bitcoinTransactionFeesPopUp[0],bitcoinTransactionFeesPopUp[1],bitcoinTransactionFeesPopUp[2],bitcoinTransactionFeesPopUp[3]]"
                    @change="updateTotalTransactionCost($event)"
                  >  
                    <template v-slot:selection="{ item }"> 
                      <div class="text-right" style="width:100%;">
                        <b>{{ item.speed }} /
                        {{ item.text }}</b>
                      </div>
                    </template>

                  </v-select> -->
                  <!-- <div class="mt-n4 mr-5 pr-2" style="z-index:999">
                    <b><span style="color:#484848; font-size:80%">{{  transactionFee.value }} BTC</span></b>
                  </div> -->
                </v-col>
              </v-row>

              <v-row class="ml-3 mr-3">
                <v-col cols="5" class="text-right">
                  <b>Service fee </b>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon  
                        v-bind="attrs"
                        v-on="on"
                        small 
                        class="pb-1"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <div class="text-center">Keeps TipBit running.</div>
                  </v-tooltip>
                </v-col>
                <v-col cols="7" class="text-right">
                  <b>$0.25<br /><span style="color:#484848; font-size:80%">{{tipBitTransactionFeeInBitcoin}} BTC</span></b>
                </v-col>
              </v-row>

              <v-row class="ml-3 mr-3">
                <v-col cols="5" class="text-right">
                  <b>Total cost</b>
                </v-col>
                <v-col cols="7" class="text-right">
                  <b>${{totalTransactionCostInDollars}}<br /><span style="color:#484848; font-size:80%">{{totalTransactionCostInBitcoin}} BTC</span></b>
                </v-col>
              </v-row>
              
              <v-row class="text-center mt-3 pb-1 ml-3 mr-3">
                <v-col cols="12">
                  <v-btn 
                    color="success lighten-1"
                    large
                    rounded
                    class="no-uppercase"
                    @click="openUnlockWalletAndSendDialog()"
                  >
                    <h5 class="m-0"><b>Tip {{cryptoDialogUserName}}</b></h5>
                    <!-- <v-icon large right>mdi-heart</v-icon><br /> -->
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="3" class="mt-n3 pl-0 pr-0 ml-0 mr-0">

              <div class="text-center mb-3">

                <h3 class="mt-0">You just tipped</h3>
                <h3><b>{{tipInBitcoin}} BTC ({{tipInDollars}})</b></h3>
                <h3 style="margin-top:-.17em">to</h3>
                <h2><b>{{ cryptoDialogUserName }}</b></h2>
                <v-btn
                  x-large
                  fab
                  class="mt-2"
                  color="blue lighten-1 white--text"
                  @click="finishTransaction()"
                >
                  <v-icon x-large>mdi-check-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-stepper-content>

          </v-stepper-items>
        </v-stepper>


            <v-expansion-panels accordion flat class="mt-4" v-if="transactionStepper == 1" :value="expansionPanel">
                <v-expansion-panel>
                  <v-expansion-panel-header class="text-center">Address</v-expansion-panel-header>
                  <v-expansion-panel-content class="mt-n2">
                    <v-row no-gutters align="center" class="mt-n4">
                      <v-col cols="11" class="pr-3">
                        <v-text-field
                          style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                          :value="sendToBitcoinAddress"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-icon @click="copyToClipboard(sendToBitcoinAddress)" class="mt-n2">mdi-content-copy</v-icon>
                      </v-col>
                      <v-col class="text-center mt-1">
                        <qrcode-vue :value="sendToBitcoinAddress" size="175" level="H" />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>


      </v-card-text>
    </v-card>


    <v-dialog
        v-if="unlockWalletAndSendDialog"
        v-model="unlockWalletAndSendDialog"
      >
        <v-card>
          
          <v-card-title>
            Unlock Wallet
            <v-spacer></v-spacer>
            <v-btn icon @click="unlockWalletAndSendDialog = false">
            <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row no-gutters>
              
              <v-col>
                <v-text-field
                v-model="cryptoPassword"
                type="password"
                label="Password"
                style="font-size:2em"
                class="largeTextField"
                :loading="passwordUnlockLoading"
                ></v-text-field>
              </v-col>
                    
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="unlockWalletAndSendDialog = false"
              color="blue darken-1"
              text
              class="no-uppercase"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="unlockWalletAndSend()"
              color="blue darken-1"
              text
              class="no-uppercase"
            >
              Submit
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>


      <!-- WRONG WALLET PASSWORD DIALOG -->
      <v-dialog
        v-if="wrongWalletPasswordDialog"
        v-model="wrongWalletPasswordDialog"
      >
        <v-card>
          
          <v-card-title>
            Unlock Wallet
            <v-spacer></v-spacer>
            <v-btn icon @click="wrongWalletPasswordDialog = false">
            <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <p>
              The password you supplied is incorrect. Try again.
            </p> 
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="wrongWalletPasswordDialog = false"
              color="blue darken-1"
              text
              class="no-uppercase"
            >
              OK
            </v-btn>
            
          </v-card-actions>

        </v-card>
        </v-dialog>

      
      <v-snackbar
          v-model="copyToClipboardToast"
          color="blue lighten-5"
          :timeout="2500"
      >
        <div class="black--text text-center">Copied to Clipboard</div>
      </v-snackbar>

      <audio id="successSound">
        <source src="../assets/success.mp3" type="audio/mpeg">
      </audio>

  </v-main>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { query, where, getDocs, getDoc, doc, addDoc, collection} from "firebase/firestore"
import { db } from "@/main"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import QrcodeVue from 'qrcode.vue'
import { getFunctions, httpsCallable } from "firebase/functions"
import bip84 from "bip84"
import sb from 'satoshi-bitcoin'
import * as bitcoin from "bitcoinjs-lib"
import CryptoJS from "crypto-js"



export default {

  data: function () {
    return {

      expansionPanel: '',
      wrongWalletPasswordDialog: false,

      sendToBitcoinAddress: '',

      unlockWalletAndSendDialog: false,

      loading: false,

      bitcoinNotLoaded: true,

      copyToClipboardToast: false,

      bitcoinAddress: '',

      transactionFee: {},
      bitcoinTransactionFeesPopUp: [],

      totalTransactionCostInDollars: "$0.00",
      totalTransactionCostInBitcoin: "0",

      tipBitTransactionFeeInBitcoin: '',

      bitcoinTransactionFees: {},

      priceOfBitcoin: 0,

      transactionStepper: 1,
			loggedIn: false,
			firstName: '',
			tipInDollars: '$',
			cryptoDialogUserName: '',
			cryptoDialogUserBitcoinAccount: 'sex',
			activeBitcoinAddress: '',
			tipInBitcoin: '',
			user: {},

			messageToTippee: '',

      sendTipNextButtonDisabled: true,

      dollarPricesOfMyBitcoin: [],
      dollarPriceOfMyConfirmedBitcoin: 0,
      dollarPriceOfMyUnconfirmedBitcoin: 0,
      confirmedBitcoin: 0,
      dollarPriceOfMyAvailableBitcoin: 0,

      account: {
        name: '',
        addressCount: 0,
        zpub: '',
      },

      cryptoPassword: '',
      passwordUnlockLoading: false,
      transactionProcessing: false,


		}
  },

  created() {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.initUser(user)
      }
      else {

        this.expansionPanel = 0

      }
      
    })
  },


  mounted() {
    const profileURLQuery = query(collection(db, "users"), where("profileURL", "==", this.$route.params.puid))

    getDocs(profileURLQuery).then((querySnapshot) => {
      if (!querySnapshot.empty) {

        querySnapshot.forEach((doc) => {

          this.getImage('profile_pics/'+doc.data().profilePic, 'profilePicture')
          this.account.name = doc.data().firstName
          this.cryptoDialogUserName = doc.data().firstName
          this.cryptoDialogUserID = doc.id

          console.log(this.$route.params.puid + '.jpg')

          console.log(doc.id)

          const functions = getFunctions()
          const getUserBitcoinAddress = httpsCallable(functions, 'getUserBitcoinAddress')
          getUserBitcoinAddress({uid: doc.id}).then(result => {
            console.log(result.data.address)
            this.sendToBitcoinAddress = result.data.address
            this.loadingAddress = false
          })
          .catch(error => {
            console.log(error)
          })
          // if (this.loggedIn) {
            this.loading = true
            const getNetworkInfo = httpsCallable(functions, 'getNetworkInfo')
            getNetworkInfo().then(result => {
              this.bitcoinTransactionFees = result.data.data.mempool.blocks[0]
              console.log(this.bitcoinTransactionFees)
              const getBitcoinPrice = httpsCallable(functions, 'getBitcoinPrice')
              getBitcoinPrice().then(result => {
                console.log(result.data.data.value)
                this.priceOfBitcoin = result.data.data.value
                this.loading = false
              })
            })
          // }
          })


      }

      else {

        const q = query(collection(db, "users"), where("profilePic", "==", this.$route.params.puid + '.jpg'))
        getDocs(q).then((querySnapshot) => {

        querySnapshot.forEach((doc) => {

          this.getImage('profile_pics/'+doc.data().profilePic, 'profilePicture')
          this.account.name = doc.data().firstName
          this.cryptoDialogUserName = doc.data().firstName
          this.cryptoDialogUserID = doc.id

          console.log(this.$route.params.puid + '.jpg')

          console.log(doc.id)

          const functions = getFunctions()
          const getUserBitcoinAddress = httpsCallable(functions, 'getUserBitcoinAddress')
          getUserBitcoinAddress({uid: doc.id}).then(result => {
            console.log(result.data.address)
            this.sendToBitcoinAddress = result.data.address
            this.loadingAddress = false
          })
          .catch(error => {
            console.log(error)
          })
          // if (this.loggedIn) {
            this.loading = true
            const getNetworkInfo = httpsCallable(functions, 'getNetworkInfo')
            getNetworkInfo().then(result => {
              this.bitcoinTransactionFees = result.data.data.mempool.blocks[0]
              console.log(this.bitcoinTransactionFees)
              const getBitcoinPrice = httpsCallable(functions, 'getBitcoinPrice')
              getBitcoinPrice().then(result => {
                console.log(result.data.data.value)
                this.priceOfBitcoin = result.data.data.value
                this.loading = false
              })
            })
          // }
          })

          

        })

      }
    })

    // getDoc(docRef).then((userDoc) => {
    //   if (userDoc.exists()) {

    //     this.getImage('profile_pics/'+userDoc.data().profilePic, 'profilePicture')

    //     console.log(userDoc.data().firstName)

    //     this.account.addressCount = userDoc.data().addressCount
    //     this.account.name = userDoc.data().firstName
    //     const functions = getFunctions()
    //     const getZPub = httpsCallable(functions, 'getZPub')
    //     getZPub({uid:this.$route.params.uid}).then(result => {
    //       this.account.zpub = result.data.data.zpub
    //       const account = new bip84.fromZPub(result.data.data.zpub)
    //       this.bitcoinAddress = account.getAddress(userDoc.data().addressCount)
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    //   }
    // })
  },


	methods: {

    finishTransaction(){
      this.transactionStepper = 1
      this.tipInDollars='$'
      this.tipInBitcoin=''
      this.sendTipNextButtonDisabled = true
      window.scrollTo(0, 0)
      const functions = getFunctions()
      const getUserBitcoinAddress = httpsCallable(functions, 'getUserBitcoinAddress')
      getUserBitcoinAddress({uid: this.cryptoDialogUserID}).then(result => {
        console.log(result.data.address)
        this.sendToBitcoinAddress = result.data.address
        this.loadingAddress = false
      })
      .catch(error => {
        console.log(error)
      })
    },

    async initUser(user) {
      this.user = user
      const userDocRef = doc(db, "users", user.uid)
      const userDoc = await getDoc(userDocRef)
      if (userDoc) {  
        this.user.firstName = userDoc.data().firstName
        this.user.profilePic = userDoc.data().profilePic
        this.user.role = userDoc.data().role
        this.user.smsmessages = userDoc.data().smsmessages
        this.hospitalityWorker = userDoc.data().hospitalityWorker
        this.walletSetupFinished = userDoc.data().walletSetupFinished
        if (this.walletSetupFinished == true){
          const functions = getFunctions()
          const getZPub = httpsCallable(functions, 'getZPub')
          getZPub({uid:this.user.uid}).then(result => {
            this.user.zpub = result.data.data.zpub
            this.user.addressCount = result.data.data.addressCount
            this.user.addressCountFloor = result.data.data.addressCountFloor
            this.getBitcoinValue(this.user.zpub, this.user.addressCount, this.user.addressCountFloor)
            setInterval(this.getBitcoinValue, 5000000, this.user.zpub, this.user.addressCount, this.user.addressCountFloor)
          })
          .catch(error => {
            console.log(error)
          })
        }
      }
      this.loggedIn = true       
    },


    async getBitcoinValue(zpub, addressCount, addressCountFloor) {
      console.log(zpub)
      console.log(addressCount)
      console.log(addressCountFloor)
      const functions = getFunctions()
      const getBitcoinPrice = httpsCallable(functions, 'getBitcoinPrice')
      const getAddressBalance = httpsCallable(functions, 'getAddressBalance')
      const account = new bip84.fromZPub(zpub)
      // const docRef = doc(db, "users", this.user.uid)
      // getDoc(docRef).then((userDoc) => {
      //   this.user.addressCount = userDoc.data().addressCount
      // })
      const bitcoinPriceResult = await getBitcoinPrice()
      if (bitcoinPriceResult) {
        const bitcoinPrice = Number(bitcoinPriceResult.data.data.value)
        console.log(bitcoinPrice)
        const balancePromises = []
        for (let i = addressCountFloor; i < addressCount; i++) {
          const address = account.getAddress(i)
          console.log(i + ' ' +address)
          const addressBalance = await getAddressBalance({address:address})
          console.log(addressBalance)
          balancePromises.push(addressBalance)
        }
        const balances = await Promise.all(balancePromises)
        if (balances) {
          this.dollarPriceOfMyConfirmedBitcoin = 0
          this.dollarPriceOfMyUnconfirmedBitcoin = 0
          for (let i = 0; i < balances.length; i++) {

            console.log(i + ' ' + balances[i].data.data)

            if (!balances[i].data.data.error_message){

              console.log(balances[i])

              const bitcoinValueConfirmed = Number(balances[i].data.data.confirmed)
              console.log(bitcoinValueConfirmed)
              const dollarValueConfirmed = (bitcoinValueConfirmed*bitcoinPrice)
              this.dollarPriceOfMyConfirmedBitcoin += dollarValueConfirmed
              this.confirmedBitcoin += Number(balances[i].data.data.confirmed)

              const bitcoinValueUnconfirmed = Number(balances[i].data.data.unconfirmed)
              console.log(bitcoinValueUnconfirmed)
              const dollarValueUnconfirmed = (bitcoinValueUnconfirmed*bitcoinPrice)
              this.dollarPriceOfMyUnconfirmedBitcoin += dollarValueUnconfirmed
            }

          }

          this.dollarPriceOfMyConfirmedBitcoin = this.dollarPriceOfMyConfirmedBitcoin.toFixed(2)
          console.log(this.dollarPriceOfMyConfirmedBitcoin)
          this.dollarPriceOfMyUnconfirmedBitcoin = Number(this.dollarPriceOfMyUnconfirmedBitcoin).toFixed(2)
          console.log(this.dollarPriceOfMyUnconfirmedBitcoin)
          this.dollarPriceOfMyAvailableBitcoin = (Number(this.dollarPriceOfMyConfirmedBitcoin) + Number(this.dollarPriceOfMyUnconfirmedBitcoin)).toFixed(2)
          console.log(this.dollarPriceOfMyAvailableBitcoin)

          this.bitcoinNotLoaded = false

        }
      }
    },

    copyToClipboard(address){
      navigator.clipboard.writeText(address)
      this.copyToClipboardToast = true
    },
    
    
    async getMyBitcoinValue(zpub) {
      console.log('getMyBitcoinValue')
      const functions = getFunctions()
      const getBitcoinPrice = httpsCallable(functions, 'getBitcoinPrice')
      const getAddressBalance = httpsCallable(functions, 'getAddressBalance')
      const account = new bip84.fromZPub(zpub)
      const docRef = doc(db, "users", this.user.uid)
      getDoc(docRef).then((userDoc) => {
        this.user.addressCount = userDoc.data().addressCount
      })
      const bitcoinPriceResult = await getBitcoinPrice()
      if (bitcoinPriceResult) {
        const bitcoinPrice = bitcoinPriceResult.data.data.value
        const balancePromises = []
        for (let i = 0; i < this.user.addressCount; i++) {
          const address = account.getAddress(i)
          const addressBalance = await getAddressBalance({address:address})
          balancePromises.push(addressBalance)
        }
        const balances = await Promise.all(balancePromises)
        if (balances) {
          for (let i = 0; i < balances.length; i++) {
            const dollarValue = (balances[i].data.data.confirmed*bitcoinPrice)
            this.dollarPriceOfMyConfirmedBitcoin += dollarValue
          }
          this.dollarPriceOfMyConfirmedBitcoin = this.dollarPriceOfMyConfirmedBitcoin.toFixed(2)
          this.bitcoinNotLoaded = false
        }
      }
    },


    getZPub() {
      const functions = getFunctions()
      const getZPub = httpsCallable(functions, 'getZPub')
      getZPub({uid:this.user.uid}).then(result => {
        this.user.zpub = result.data.data.zpub
        this.userWalletPublicKey = result.data.data.zpub
      })
      .catch(error => {
        console.log(error)
      })
    },

   
    getImage(path, id) {
      console.log(id)
      const storage = getStorage()
      getDownloadURL(ref(storage, path))
      .then(url=>{
        if (document.getElementById(id)){
          const image = document.getElementById(id)
          image.setAttribute('src', url)
        }
      }).catch(function(error){
        console.log(error)
        if (document.getElementById(id)){
          document.getElementById(id).setAttribute('src', 'https://firebasestorage.googleapis.com/v0/b/whosworking.appspot.com/o/profile_pics%2FProfile_Pic_Placeholder.jpg?alt=media&token=fe0bf717-0b09-4219-86c3-78cf63e1ed47')
        }
      })
    },


    
    addTipKey(key) {
      if (key === 'delete') {
        if (this.tipInDollars != "$") {
          let newTip = this.tipInDollars
          this.tipInDollars = newTip.slice(0,-1)
        }
      }
      else {
        let tipInDollars = this.tipInDollars + key
        const regex = new RegExp('^[a-zA-Z0-9,]*.[a-zA-Z0-9,]{0,2}$')
        const pass = regex.test(tipInDollars.slice(1))
        if (pass) {
          this.tipInDollars += key
        }   
      }
      this.convertToBitcoin()
      const availableBitcoin = Number(this.dollarPriceOfMyAvailableBitcoin)
      const transactionFee = Number(this.transactionFee.text)
      const tipbitFee = .25
      const totalCost = (availableBitcoin - transactionFee - tipbitFee).toFixed(2)
      const tipString = this.tipInDollars.substring(1,this.tipInDollars.length)
      const tip = Number(tipString)
      if (totalCost > tip && tip!=0) {
        this.sendTipNextButtonDisabled = false
      }
      else {
        this.sendTipNextButtonDisabled = true
      }
    },
    
    convertToBitcoin() {
     this.tipInBitcoin = (this.tipInDollars.substring(1,this.tipInDollars.length)/this.priceOfBitcoin).toFixed(8)
     this.tipBitTransactionFeeInBitcoin = (.25/this.priceOfBitcoin).toFixed(8)
     const size = 210
     console.log(this.bitcoinTransactionFees.median_fee_rate)
     this.transactionFee = {
       text: (sb.toBitcoin(this.bitcoinTransactionFees.median_fee_rate * size) * this.priceOfBitcoin).toFixed(2),
       value: (sb.toBitcoin(this.bitcoinTransactionFees.median_fee_rate * size)).toFixed(8),
     }
     this.updateTotalTransactionCost()
   },
   
   updateTotalTransactionCost() {  
     let dollars = this.transactionFee.text
     let bitcoin = this.transactionFee.value
     this.totalTransactionCostInDollars = ((Number(this.tipInDollars.substring(1,this.tipInDollars.length)) + Number(dollars)) + Number(.25)).toFixed(2)
     this.totalTransactionCostInBitcoin = ((Number(this.tipInBitcoin) + Number(bitcoin)) + Number(this.tipBitTransactionFeeInBitcoin)).toFixed(8)
   
     console.log(this.confirmedBitcoin)
     console.log(this.totalTransactionCostInBitcoin)
     if (this.totalTransactionCostInBitcoin < this.confirmedBitcoin){
       this.sendTipButtonDisabled = false
     }
     else {
       this.sendTipButtonDisabled = true
     }
   },



    async sendBitcoinTest() {

      this.transactionProcessing = true

      const successAudio = document.getElementById("successSound")
      successAudio.load()

      const functions = getFunctions()

      const getAppBitcoinAddress = httpsCallable(functions, 'getAppBitcoinAddress')
      const appBitcoinAddressResult = await getAppBitcoinAddress()

      if (appBitcoinAddressResult) {

        const appBitcoinAddress = appBitcoinAddressResult.data.data
        const receiverAddress = this.sendToBitcoinAddress      
        const senderAccount = new bip84.fromZPrv(this.user.zprv)

        const satoshisToSendAsTip = sb.toSatoshi(this.tipInBitcoin)
        const satoshisForNetwork = sb.toSatoshi(this.transactionFee.value)
        const satoshisForTipbit = sb.toSatoshi(this.tipBitTransactionFeeInBitcoin)
        const totalSatoshisToSpend = satoshisToSendAsTip + satoshisForNetwork + satoshisForTipbit

        const getZPub = httpsCallable(functions, 'getZPub')
        const zpubResult = await getZPub({uid:this.user.uid})
        
        if (zpubResult){

          let inputs = []

          console.log(zpubResult.data.data.addressCountFloor)
          console.log(zpubResult.data.data.addressCount)

          const addressCountFloor = zpubResult.data.data.addressCountFloor
          const addressCount = zpubResult.data.data.addressCount 
          let satoshisAvailable = 0
          let keyPairs = []

          const psbt = new bitcoin.Psbt()

          for (let i = addressCountFloor; i < addressCount; i++) {
            
            const address = senderAccount.getAddress(i)
            // console.log('-------'+address+'---------')
            const privateKey = senderAccount.getPrivateKey(i)
            const keypair = bitcoin.ECPair.fromWIF(privateKey)
            // console.log(JSON.stringify(keypair))
            const getAddressUnspentOutputs = httpsCallable(functions, 'getAddressUnspentOutputs')
            const addressUnspentOutputs = await getAddressUnspentOutputs({address: address})

            // console.log(addressUnspentOutputs)

            if (addressUnspentOutputs.data.status == "success" && addressUnspentOutputs.data.data.outputs.length > 0) {

                for (let addressUnspentIndex = 0; addressUnspentIndex < addressUnspentOutputs.data.data.outputs.length; addressUnspentIndex++){       

                  // meaning it's not confirmed so we keep it out of the transaction ...

                  if (addressUnspentOutputs.data.data.outputs[addressUnspentIndex].block != null) {

                    console.log('-------'+address+'---------')

                    console.log('successs: '+addressUnspentOutputs.data.data.outputs[addressUnspentIndex])

                    // console.log(addressUnspentOutputs.data.data.outputs.length)

                    let utxo = {}
                    utxo.hash = addressUnspentOutputs.data.data.outputs[addressUnspentIndex].hash
                    utxo.index = addressUnspentOutputs.data.data.outputs[addressUnspentIndex].index
                    const script = Buffer.from(addressUnspentOutputs.data.data.outputs[addressUnspentIndex].script, 'hex')
                    utxo.witnessUtxo = {
                      script: script,
                      value: sb.toSatoshi(addressUnspentOutputs.data.data.outputs[addressUnspentIndex].value)
                    }
                    satoshisAvailable += sb.toSatoshi(addressUnspentOutputs.data.data.outputs[addressUnspentIndex].value)
                    inputs.push(utxo)
                    keyPairs.push(keypair)
                
                }
              }
            }
            else {
              // console.log('yes, error message or non existent or not confirmed')
              // subtractAddressCountFloor += 1
              // console.log(addressUnspentOutputs.data.data.error_message)
              // await updateDoc(doc(db, "users", this.user.uid), {
              //   addressCountFloor: increment(1),
              // })
            }
            if (totalSatoshisToSpend < satoshisAvailable) {
              break
            }
          }

          let satoshisToKeep = satoshisAvailable - satoshisToSendAsTip - satoshisForNetwork - satoshisForTipbit

          psbt.addInputs(inputs)

          psbt.addOutput({
            address: receiverAddress,
            value: satoshisToSendAsTip,
          })
          psbt.addOutput({
            address: appBitcoinAddress,
            value: satoshisForTipbit,
          })
          const changeAddress =  senderAccount.getAddress(addressCount)
          psbt.addOutput({
            address: changeAddress,
            value: satoshisToKeep,
          })

          for (let k = 0; k < keyPairs.length; k++){       
            psbt.signInput(k,keyPairs[k])
          }

          // psbt.signAllInputs(keyPairs)

          psbt.finalizeAllInputs()

          // console.log("inputs " +inputs)

          // console.log("satoshis available: " + satoshisAvailable)
          // console.log("satoshis to send as tip: " + satoshisToSendAsTip)
          // console.log("satoshis for miners: " + satoshisForNetwork)
          // console.log("satoshis for tipbit: " + satoshisForTipbit)
          // console.log("total satoshis to spend: " + totalSatoshisToSpend)
          // console.log("satoshis to keep: " + satoshisToKeep)


          // console.log('satoshis as tip' + satoshisToSendAsTip)

          // console.log('to address ' + receiverAddress)
          // console.log('app bitcoin address for service fee ' + appBitcoinAddress)
          // console.log('change address ' + changeAddress)
          // console.log('subtract address count ' + (subtractAddressCountFloor - 1))

          // console.log('keypairs: '+JSON.stringify(keyPairs))

          const hex = psbt.extractTransaction().toHex()

          console.log(hex)
          console.log(psbt)
          
          const broadcastTransaction = httpsCallable(functions, 'broadcastTransaction')
          broadcastTransaction({tx_hex: hex}).then(result => {
            this.transactionStepper = 3
            this.sendTipStepper = 4
            successAudio.play()
            this.transactionProcessing = false
            console.log(result)
          }).catch(err => console.error(err))

          // this.transactionStepper = 3
          // this.sendTipStepper = 4
          // successAudio.play()
          // this.transactionProcessing = false

          let bodyText
          if (this.sendToProfilePicURL){
            bodyText =  this.user.displayName + " just tipped you " + this.tipInBitcoin + " BTC (" + this.tipInDollars+ ")"
          }
          else if (this.formattedNumber) {
            bodyText = this.user.displayName + " just tipped you " + this.tipInBitcoin + " BTC (" + this.tipInDollars+ ")\n\nTo claim your Bitcoin visit https://tipbit.co and login"
          }
          else {
            bodyText = this.user.displayName + " just tipped you " + this.tipInBitcoin + " BTC (" + this.tipInDollars+ ")"
          }

          const userRef = doc(db, "users", this.cryptoDialogUserID)
          const userDoc = await getDoc(userRef)
          if (userDoc){
            addDoc(collection(db, "smsmessages"), {
              to: userDoc.data().phone,
              body: bodyText,
            })
          }
        }
      }
    },


    async openUnlockWalletAndSendDialog(){
      this.unlockWalletAndSendDialog = true
    },


    async unlockWalletAndSend() {
      this.passwordUnlockLoading = true
      const functions = getFunctions()
      const getZPrv = httpsCallable(functions, 'getZPrv')
      getZPrv().then(result => {
        const privateKey = CryptoJS.AES.decrypt(result.data.data.zprv, this.cryptoPassword)
        try {
          const wordArray = JSON.parse(privateKey.toString(CryptoJS.enc.Utf8))
          this.user.zprv = wordArray.zprv
          this.passwordUnlockLoading = false
          if (document.getElementsByClassName('v-dialog--active')) {
            document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
          }
          this.unlockWalletAndSendDialog = false
          this.passwordUnlockLoading = false
          this.cryptoPassword = ''
          this.sendBitcoinTest()
        } catch (ex) {
          console.log('failed')
          this.cryptoPassword = ''
          this.wrongWalletPasswordDialog = true
          this.passwordUnlockLoading = false
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

		

	},
	
	components: {
    QrcodeVue,
  },

}

</script>