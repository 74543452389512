<template>
    <v-main>
      <v-container class="p-4" style="font-size:105%">  
  
        <v-row no-gutters class="text-center">
          <v-col>
            <h1 style="font-size:215%"><b>How To Add Your Tip Jar to Your Workplace</b></h1>
          </v-col>
        </v-row>
  
        <v-row no-gutters>
            <v-col>
                <ol class="h3">
                    <li>Login to TipBit</li>
                    <v-img :src="images[0]" />
                    <li>Setup Your Account</li>
                    <li>Search for your Workplace</li>
                    <li>Add Your Tip Jar (You Must be at Your Workplace)</li>
                    <li>Your Tip Jar Will Appear on the Map</li>
                </ol>
            </v-col>
        </v-row>

      </v-container>  
    </v-main>
  </template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage"

export default {

  data: function () {
      return {
        images: [],
      }
  },

  mounted() {
    this.getImage('howTo1.jpeg')
  },

  methods: {

    getImage(imageUrl) {
      const storage = getStorage()
      getDownloadURL(ref(storage, 'assets/'+ imageUrl))
        .then((url) => {
          this.images.push(url)
        })
        .catch(error => {
          console.log(error)
      })
    }

  }
  
}
</script>
  
  
  
  
  
  
  