<template>
  <div style="width: 100%; height: 100%">

      <gmap-map
        :center="center"
        :zoom="13"
        map-type-id="roadmap"
        :options="options"
        style="width: 100%; height: 91%"
      >
 
      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"></gmap-info-window>

      <gmap-marker
        :key="index"
        v-for="(m, index) in tipJars"
        :position="{lat: m.latitude, lng: m.longitude}"
        :clickable="true"
        :draggable="false"
        :icon="{ url: 'https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg', scaledSize: {width: 28, height: 28},labelOrigin: {x: 16, y: 30} }"
        @click="toggleInfoWindow(m,index)"
        :label="m.placeName"
      >
      </gmap-marker>

    </gmap-map>

    <!-- <div class="text-center mt-3 pt-1">
      <v-btn @click="getLocation" small outlined color="primary">
        Find Locations Near Me
      </v-btn>
    </div> -->

  </div>
</template>


<script>
import { db } from "@/main"
import { getDocs, collection, query, where } from "firebase/firestore"
import { getStorage, ref, getDownloadURL } from "firebase/storage"

export default {

  data: function () {
      return {

        tipDialog: false,

        tipJars: [],
        center: { lat: 0, lng: 0 },
        markers: [],

        infoWinOpen: false,
        infoWindowPos: null,
        currentMidx: null,

        infoOptions: {
          content: '',
            pixelOffset: {
            width: 0,
            height: -35
          }
        },

        options: {
          streetViewControl: false,   
          mapTypeControl: false,
          fullscreenControl: false,
           styles: [
            {
              "featureType": "administrative",
              "elementType": "geometry",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "transit",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            }
          ]
        }
        

      }
  },

  mounted() {
    this.fetchTipjars()
    this.getLocation()
  },

  methods: {

    toggleInfoWindow (marker, idx) { 

        this.infoWindowPos = {lat: marker.latitude, lng: marker.longitude}

        let html = ''

        const self = this
        const storage = getStorage()
        const tipjars = Object.values(marker.tipjars)

        tipjars.forEach(function(tipjar,index) {
          
          console.log(index)
          console.log(tipjar)
          html += '<div style="float:left;" class="text-center mr-2 border p-1 rounded"><img id="tipjarProfilePic--'+index+'" style="width:50px" /><div class="mt-1">'+tipjar.firstName + '</div><div class="text-center" style="color:blue" id="tipjar--'+index+'">Tip Me</div>'+tipjar.role+'</div>'
          
          getDownloadURL(ref(storage, 'profile_pics/'+tipjar.profilePic))  
          .then(url=>{
            console.log(url)
            if (document.getElementById('tipjarProfilePic--'+index)){
              const image = document.getElementById('tipjarProfilePic--'+index)
              image.setAttribute('src', url)
              tipjar.profilePic = url
            }
          })
          .catch(function(error){
            console.log(error)
          })

          document.addEventListener("click", (event) => {
            if (event.target.id && event.target.id === 'tipjar--'+index) {
              self.$emit('clicked',tipjar)
            }
          });
        
        })

        this.infoOptions.content = '<div class="mb-2"><b>'+marker.placeName+'</b><div>' + marker.street_number + ' ' + marker.route + '</div><div>' + marker.locality + ', ' + marker.administrative_area_level_1 + '</div></div>' + html;
    
        //check if its the same marker that was selected if yes toggle
        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;
        }

    },

    fetchTipjars() {
      this.tipJars = []      
      const tipjars = query(collection(db, "tipjars_at_places"),where("tipjarsCount", ">", 0))
      getDocs(tipjars).then((result) => {
        result.forEach((doc) => {
          this.tipJars.push(doc.data())
        }) 
      })
      this.tipJars.sort((a, b) => a.firstName.localeCompare(b.firstName))
    },

    getLocation(){
      navigator.geolocation.getCurrentPosition(this.centerMap)
    },

    centerMap(position) {
      this.center.lat = position.coords.latitude
      this.center.lng = position.coords.longitude
    },


  },

}
  
</script>