import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AngelSignUp from '../views/AngelSignUp.vue'
import AngelAdvisor from '../views/AngelAdvisor.vue'
import ProfileView from '../views/ProfileView.vue'
import ScheduleView from '../views/ScheduleView.vue'
import TipbitLocations from '../views/TipbitLocations.vue'
import SMSMarketing from '../views/SMSMarketing.vue'
import HowTo from '../views/HowTo.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },

  {
    path: '/howto',
    name: 'how to',
    component: HowTo,
  },

  {
    path: '/_smsmarketing',
    component: SMSMarketing,
  },

  {
    path: '/locations',
    name: 'locations',
    component: TipbitLocations, 
  },

  {
    path: '/profile/:puid',
    name: 'profile',
    component: ProfileView,
  },

  {
    path: '/schedule/:id',
    name: 'schedule',
    component: ScheduleView,
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/angel',
    name: 'angel',
    component: AngelSignUp,
  },
  {
    path: '/angel-advisor',
    name: 'angel advisor',
    component: AngelAdvisor,
  },


]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router