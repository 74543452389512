<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-textarea rows="2"></v-textarea>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn>Send SMS Message</v-btn>
            </v-card-actions>
        </v-card>
        <v-container>
        <v-row class="mt-3"> 
            <v-col cols="4">                                 
              First Name
            </v-col>
            <v-col cols="4">
                Wallet Setup Finished
            </v-col>
            <v-col cols="4">
               SMS Messages
            </v-col>
        </v-row>
        <v-row  v-for="(user,i) in users" :key="i" > 
            <v-col cols="4">                                 
                {{ user.firstName }} 
            </v-col>
            <v-col cols="4">
                {{  user.walletSetupFinished }}
            </v-col>
            <v-col cols="4">
                {{  user.smsmessages }}
            </v-col>
        </v-row>
    </v-container>
    </div>
</template>

<script>
import { orderBy, query, collection, getDocs } from "firebase/firestore"
import { db } from "@/main"

export default {

    data: function () {
        return {
            users: [],
        }
    },


    mounted() {
        this.getUsers()
    },

    methods: {

        getUsers() {
            const q = query(collection(db, "users"),orderBy("firstName"))
            getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const user = doc.data()
                this.users.push(user)
            })
        })
        }
    }

}

</script>