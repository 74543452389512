<template>
  <v-main>
    <v-container>

      <v-row>
        <v-col cols="12" class="text-center">
            <h3 v-if="user"><b>{{user.firstName}}'s Upcoming Shifts</b></h3>
        </v-col>
      </v-row>


      <v-row>
         <v-col class="text-center mt-n3">
            <b-img
              id="cryptoDialogAvatar"
              thumbnail
            />   
        </v-col>
      </v-row>

      <v-row class="mt-n4">

        <v-col cols="12">

          <v-simple-table v-if="myShifts.length > 0">

            <template v-slot:default>

              <tbody style="font-size:200%">
                <tr
                  v-for="(shift, index) in myShifts"
                  :key="shift.date + index"
                >
                  <td>
                    <div class="pt-2 pb-2"><b>{{ formatDate(shift.date) }} | {{ formatTime(shift.startTime) }}-{{ formatTime(shift.endTime) }}<br />{{shift.placeName}}</b></div>
                  </td>
                
                </tr>
              </tbody>

            </template>

          </v-simple-table>

          <!-- <h5 v-if="myShifts.length < 1" class="text-center">You have not shared any upcoming shifts</h5> -->

        </v-col>

      </v-row>

    </v-container>
  </v-main>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { query, collection, where, getDoc, doc, orderBy, getDocs } from "firebase/firestore"
import { db } from "@/main"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import moment from "moment"

export default {

  data: function () {
    return {

      loggedIn: true,
      firstName: '',
      tipInDollars: '$',
      user: {},

      messageToTippee: '',

      myShifts: [],

      todaysDate: '',

    }
  },

	mounted() {
		const auth = getAuth()
    this.todaysDate = moment().format('YYYY-MM-DD')

    onAuthStateChanged(auth, (user) => {
      const docRef = doc(db, "users", this.$route.params.id)
      getDoc(docRef).then((userDoc) => {
        if (userDoc.exists()) {
          this.user = userDoc.data()    
          this.fetchImage('profile_pics/'+this.user.profilePic,'cryptoDialogAvatar')
          this.fetchMyShifts()
        }
      })
      if (user) {
        this.loggedIn = true
      }
    })
	},


	methods: {

    formatDate(date) {
      const newDate = moment(date).format("ddd, MMM Do, YYYY")
      return newDate
    },

    formatTime(time) {
        const newTime = moment(time, 'hh:mm:ss').format("h:mma")
        return newTime
    },

    async fetchMyShifts() {
      let utcDate = moment(this.todaysDate).valueOf() - 172800000
      const q = query(collection(db, "shifts"), where("uid", "==", this.$route.params.id ), where("utcDate", ">=", utcDate), orderBy("utcDate", "asc"))
      const querySnapshot = await getDocs(q)
      if (querySnapshot.empty) {
        console.log("No Results")
      }
      else {
        querySnapshot.forEach((doc) => {
          if (moment(this.todaysDate).valueOf() <= moment(doc.data().date).valueOf()) {  
              let newShift = {}
              newShift = doc.data()
              newShift.id = doc.id
              this.myShifts.push(newShift)
            }
        })
      }
    },

    fetchImage(path, id) {
      console.log(id)
      const storage = getStorage()
      getDownloadURL(ref(storage, path))
      .then(url=>{
        console.log(url)
        if (document.getElementById(id)){
          const image = document.getElementById(id)
          image.setAttribute('src', url)
        }
      }).catch(function(error){
        console.log(error)
        if (document.getElementById(id)){
          document.getElementById(id).setAttribute('src', 'https://firebasestorage.googleapis.com/v0/b/whosworking.appspot.com/o/profile_pics%2FProfile_Pic_Placeholder.jpg?alt=media&token=fe0bf717-0b09-4219-86c3-78cf63e1ed47')
        }
      })
    },
		

	},
	

}

</script>