<template>
    
      <v-dialog
        v-model="show"
        fullscreen
        scrollable
      >
        <v-card>
          
          <v-card-title>
            Privacy Policy
            <v-spacer></v-spacer>
            <v-btn icon @click="show = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>

            <v-row no-gutters>
              
              <v-col>

                  <p>Last updated: June 27, 2022</p>
                  <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service.</p>
                  <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
                  <h1 style="font-size:175%">Interpretation and Definitions</h1>
                  <h2 style="font-size:150%">Interpretation</h2>
                  <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                  <h2 style="font-size:150%">Definitions</h2>
                  <p>For the purposes of this Privacy Policy:</p>
                  <ul>
                  <li>
                  <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
                  </li>                
                  <li>
                  <p><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named tipbit.co</p>
                  </li>
                  <li>
                  <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to tipbit.co.</p>
                  </li>
                  <li>
                  <p><strong>Country</strong> refers to: California,  United States</p>
                  </li>
                  <li>
                  <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                  </li>
                  <li>
                  <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
                  </li>
                  <li>
                  <p><strong>Service</strong> refers to the Application or the Website or both.</p>
                  </li>
                  <li>
                  <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
                  </li>
                  <li>
                  <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                  </li>
                  <li>
                  <p><strong>Website</strong> refers to tipbit.co, accessible from <a href="https://www.tipbit.co" rel="external nofollow noopener" target="_blank">http://www.tipbit.co</a></p>
                  </li>
                  <li>
                  <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                  </li>
                  </ul>
                  <h1 style="font-size:175%">Collecting and Using Your Personal Data</h1>
                  <h2 style="font-size:150%">Types of Data Collected</h2>
                  <h3 style="font-size:125%">Personal Data</h3>
                  <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                  <ul>
                  <li>Name</li>
                  <li>Email Address</li>
                  <li>Phone Number</li>
                  </ul>
                  <h3 style="font-size:125%">Usage Data</h3>
                  <p>Usage Data is collected automatically when using the Service.</p>
                  <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                  <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                  <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                  <h3 style="font-size:125%">Information Collected while Using the Application</h3>
                  <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your permission:</p>
                  <ul>
                  <li>Your phone number (used for logging into to the service)</li>
                  <li>A profile picture submitted by you</li>
                  <li>A first name submitted by you</li>
                  <li>Work scheduling information submitted by you</li>
                  </ul>
                  <p>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</p>
                  <h3 style="font-size:125%">Tracking Technologies</h3>
                  <p>We use tracking technologies to anonymously track the activity on Our Service.</p>
                
                  
                  <h2 style="font-size:150%">Use of Your Personal Data</h2>
                  <p>The Company may use Personal Data for the following purposes:</p>
                  <ul>
                  <li>
                  <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
                  </li>
                  <li>
                  <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
                  </li>
                                  
                  <li>
                  <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
                  </li>
              
                  </ul>
                  <p>We may share Your personal information in the following situations:</p>
                  <ul> 
                  <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
              
                  </ul>
                  <h2 style="font-size:150%">Retention of Your Personal Data</h2>

                  <p>The Service automatically deletes Your shift information 24 hours after the date of the shift passes.</p>

                  <p>Once You cancel Your account, all of your Personal Data is deleted.</p>

                  <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.</p>

                  <p>The Company will also retain Usage Data for internal analysis purposes.</p>
                             
                  <h2 style="font-size:150%">Security of Your Personal Data</h2>
                  <p>The security of Your Personal Data is very important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                  <h1 style="font-size:175%">Children's Privacy</h1>
                  <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13.</p>
                 
                  <h1 style="font-size:175%">Links to Other Websites</h1>
                  <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site..</p>
                  <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                  <h1 style="font-size:175%">Changes to this Privacy Policy</h1>
                  <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                  <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                  <h1 style="font-size:175%">Contact Us</h1>
                  <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                  <ul>
                    <li>By email: mattspergel@gmail.com</li>
                  </ul>
                                
              </v-col>

            </v-row>
          
          </v-card-text>

          
          
        </v-card>
      </v-dialog>


</template>


<script>
export default {
  props: ['visible'],
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>