import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDec_cQtMUGn3bniVxRczs4UcOPPla6zNk',
    libraries: 'places',
  },
  installComponents: true
});


Vue.config.productionTip = false


/* Firebase */
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: "AIzaSyDyQQbB-BFosbta48XoS7SPSLHwLosnPB4",
  authDomain: "tipbit.firebaseapp.com",
  projectId: "tipbit",
  storageBucket: "tipbit.appspot.com",
  messagingSenderId: "1049740018863",
  appId: "1:1049740018863:web:fb85105da955148f5e805a",
  measurementId: "G-Q5QMVN2LEB"
};

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const functions = getFunctions(app)
getAnalytics(app)

Vue.use(require('bitcore-lib'))

// Vue Telephone Input
// import VueTelInput from 'vue-tel-input';
// import 'vue-tel-input/dist/vue-tel-input.css';
// Vue.use(VueTelInput);

import router from './router'

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
Vue.use(VueTelInputVuetify, {
  vuetify,
});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
