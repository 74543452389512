<template>
  <v-main>


    <div class="text-center pt-2">
      <h1>TipBit Locations</h1>
    </div>


        <!-- <div :key="index + 'yo'" v-for="(m, index) in tipJars">

          {{ m.position }}

        </div> -->
    

    <GmapMap
      :center="center"
      :zoom="13"
      map-type-id="roadmap"
      style="width: 100%; height: 80%"
    >
      <!-- <GmapMarker
        :position="center"
        :clickable="true"
        :draggable="true"
        @click="center=center"
      /> -->

      <GMapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" >
        <div>I am in info window</div>
      </GMapInfoWindow>

      <GmapMarker
        :key="index"
        v-for="(m, index) in tipJars"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        :icon="{ url: 'https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg' }"
        @click="toggleInfoWindow(m,index)"
        :label="m.placeName"
      >
       
      </GmapMarker>
    </GmapMap>

    <!-- <div class="text-center mt-3 pt-1">
      <v-btn @click="getLocation">
        Share My Location
      </v-btn>
    </div> -->

  </v-main>
</template>


<script>
import { db } from "@/main"
import { getDocs, collection, query } from "firebase/firestore"

export default {

  data: function () {
      return {

        tipJars: [],
        center: { lat: 0, lng: 0 },
        markers: [],

        infoWinOpen: false,
        infoWindowPos: null,

        infoOptions: {
          content: '',
            pixelOffset: {
              width: 0,
              height: -35
          }
        },

      }
  },

  mounted() {
    this.fetchWorkers()
  },

  methods: {

    toggleInfoWindow (marker, idx) { 

        console.log(marker)

        this.infoWindowPos = marker.position;
        this.infoOptions.content = marker.infoText;

        //check if its the same marker that was selected if yes toggle
        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;
        }

    },

    fetchWorkers() {
      const angels = query(collection(db, "tipjars"))
      getDocs(angels).then((result) => {
        result.forEach((doc) => {
          this.tipJars.push(doc.data())
        }) 
      })
    },


    getLocation(){
      // navigator.geolocation.getCurrentPosition(this.compareLocations)
      navigator.geolocation.getCurrentPosition(this.compareLocations)
    },


    compareLocations(position) {

      console.log(position)

      // let lat1 = this.place.address.latitude
      // let lon1 = this.place.address.longitude
      
      this.center.lat = position.coords.latitude
      this.center.lng = position.coords.longitude

      
      // const R = 6371; // Radius of the earth in km
      // var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
      // var dLon = this.deg2rad(lon2-lon1); 
      // var a = 
      //   Math.sin(dLat/2) * Math.sin(dLat/2) +
      //   Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      //   Math.sin(dLon/2) * Math.sin(dLon/2)
      // var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      // var d = (R * c)/1.609; // Distance in miles

      // if (d < .1) {
      //   this.showAddShiftPanel = true
      //   this.showAddCryptoPanel = true
      // }
      // else {
      //   this.addShiftsDialog = false
      //   this.addTipJarDialog = false
      //   this.locationFailed = true
      // }

    },

    deg2rad(deg) {
      return deg * (Math.PI/180)
    },

  },

 

}
  
</script>





